import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {MenuItem} from 'src/app/sidebar/sidebar-menu/menu-item.model';
import {ApplicationService} from './application.service';
import {ProgramService} from './program.service';
import {environment} from 'src/environments/environment';
import {SectionStatus} from '../model/app-form-builder.model';
import {FormService} from './form.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {DomService} from './dom.service';

// nameAttribute is important for GTM reporting, please get approval from Carla before making any changes to those attributes
const agentQSection: MenuItem = {
  name: 'agentQuestions',
  text: 'Agent Questions',
  nameAttribute: 'Agent Questions',
  mobileText: 'Agent',
  locked: false,
  visited: false,
  valid: false,
  active: true,
  disabled: false,
  navNumber: 0
};
export const optionalSection: MenuItem = {
  name: 'optional',
  text: 'Optional',
  nameAttribute: 'Optional',
  mobileText: 'Optional',
  locked: true,
  visited: false,
  valid: false,
  active: false,
  disabled: false,
  navNumber: 5
};

@Injectable({
  providedIn: 'root'
})

export class NavigationService {

  mainForm: FormGroup = new FormGroup({});
  programSub = this.programService.getProgramSub();
  checkForEnrollmentDeposit = false;
  possibleSections: MenuItem[] = this.generateNavItems();
  activeSection: MenuItem = this.possibleSections[0];
  agentSections = ['agentQuestions', 'yourDegree', 'previousEducation', 'personalInformation', 'agreeSubmit'];
  studentSections = ['yourDegree', 'previousEducation', 'personalInformation', 'agreeSubmit'];

  private emitActiveSection = new Subject<MenuItem>();

  constructor(
    private route: ActivatedRoute,
    public applicationService: ApplicationService,
    private formService: FormService,
    private router: Router,
    private programService: ProgramService,
    private domService: DomService
  ) {

    this.edSectionSetup();

    this.applicationService.prefillApplication.subscribe(application => {

      if (
        application.majorFoses[0] && application.majorFoses[0].programCode
        && this.programService.getCampusByProgramCode(application.majorFoses[0].programCode) === 'R'
        && this.programService.getLevelByProgramCode(application.majorFoses[0].programCode) === 'Bachelor'
      ) {
        if (!environment.isAgent && !applicationService.spcApp && !applicationService.festivalsApp) {
          this.maybeAddSection(optionalSection);
          optionalSection.locked = true;
        }
        this.checkForEnrollmentDeposit = true;
      }

    });

    // enabling setting default section on page load if a valid URL param 'section' is specified
    if (this.route.snapshot.queryParams.section) {
      const sectionUrlParam = this.route.snapshot.queryParams.section;
      for (const section in this.possibleSections) {
        if (this.possibleSections.hasOwnProperty(section)) {
          // if a section name matches the specified param, set the active section
          if (this.possibleSections[section].name === sectionUrlParam) {
            this.activeSection = this.possibleSections[section];
            this.emitActiveSection.next(this.activeSection);
          }
        }
      }
    }

    this.activeSection.visited = true;
    this.emitActiveSection.next(this.activeSection);

    this.checkLockedAndActiveSection();

    this.emitActiveSection.subscribe(section => {
      this.checkLockedAndActiveSection();

      this.maybeActivateSection(section.name);
    });


    // delete if VWOTest 705 is removed
    this.formService.prefillForm.subscribe(prefillObj => {
      setTimeout(() => {
        this.activeSection = this.possibleSections.find(section => section.name === prefillObj.activeSectionName);
      }, 500);
    });
  }

  // Consolidate setting up 4-5 separate subscriptions twice into one function. Use for agent and student form.
  public setupSectionValidation(isStudent: boolean, form: FormGroup, endSubs: Subject<any>) {
    const sections = isStudent ? this.studentSections : this.agentSections;
    sections.forEach(sectionName => {
      form.get(sectionName).valueChanges
        .pipe(takeUntil(endSubs),
          // This will determine how fast validation runs after touching the field.
          debounceTime(300),
          distinctUntilChanged())
        .subscribe(() => {
          const personalInfoSection = this.possibleSections.find(section => section.name === 'personalInformation');
          const agreeSubmit = this.possibleSections.find(section => section.name === 'agreeSubmit');
          if (sectionName === 'agentQuestions'
            || (sectionName === 'yourDegree' && form.get(sectionName).get('start_term'))
            || (sectionName === 'previousEducation' && this.programService.getCurrentProgram().programCode)
            || (sectionName === 'personalInformation' && personalInfoSection.visited)
            || (sectionName === 'agreeSubmit' && agreeSubmit.visited)) {
            // Validate the section passed to the subscription
            this.validateSection(sectionName);
          }
        });
    });
  }

  getEmitActiveSectionSub(): Observable<MenuItem> {
    return this.emitActiveSection.asObservable();
  }

  emitActiveSectionNext(sectionName: string) {
    const section = this.possibleSections.find(thisSection => thisSection.name === sectionName);
    this.emitActiveSection.next(section);
    this.validateAllSections();
  }

  updateFormForNavService(providedForm: FormGroup) {
    // This happens on init of form component and doesn't need to happen on change afterward
    // because it shares the object reference (this.applicationForm in form.component)
    this.mainForm = providedForm;
    this.possibleSections.forEach(section => {
      this.validateSection(section.name);
    });
  }

  maybeActivateSection(sectionName: string) {
    // check for validation of current section before changing
    this.validateSection(this.activeSection.name);

    const sectionToActivate = this.possibleSections.find(thisSection => {
      return thisSection.name === sectionName && !thisSection.disabled; // will not return successfully if the section is disabled
    });

    if (sectionToActivate) {

      if (this.activeSection.valid && !sectionToActivate.locked) {
        sectionToActivate.visited = true;
      }

      // students will not activate requested section unless already visited
      if (
        !environment.isAgent && !sectionToActivate.visited && !sectionToActivate.valid
      ) {
        return false;
      }

      this.activateSection(sectionToActivate);
    }

  }

  async activateSection(sectionToActivate: MenuItem) {
    this.activeSection.active = false;
    sectionToActivate.visited = true;
    sectionToActivate.active = true;
    sectionToActivate.locked = false;
    this.activeSection = sectionToActivate;

    const theElement = this.domService.selectElementAsRoot(`.${sectionToActivate.name}.screen-reader-only`) as HTMLElement;

    window.scrollTo(0, 0);
    theElement.focus();
  }

  public sectionIsActive(sectionName: string) {
    return this.possibleSections.find(section => section.name === sectionName).active;
  }

  disableSection(sectionName: string) {
    const section = this.possibleSections.find(thisSection => thisSection.name === sectionName);
    section.disabled = true;
  }

  enableSection(sectionName: string) {
    const section = this.possibleSections.find(thisSection => thisSection.name === sectionName);
    section.disabled = false;
  }

  sectionIsDisabled(sectionName: string): boolean {
    const theSection = this.possibleSections.find(thisSection => {
      return thisSection.name === sectionName && thisSection.disabled;
    });
    return !!theSection;
  }

  validateAllSections() {
    this.possibleSections.forEach(section => this.validateSection(section.name));
  }

  validateSection(sectionName: string) {
    const validatedLink = this.possibleSections.find((menuItem) => menuItem.name === sectionName);
    const thisFormSection = this.mainForm.get(sectionName) as FormGroup;
    if (sectionName === validatedLink.name && thisFormSection) {
        // Check if the formGroup is valid and has been visited and if so mark the section as valid.
        validatedLink.valid = thisFormSection.status === 'VALID' && validatedLink.visited;
      }
    let findNextSection: MenuItem;
      // unlock next section if current is valid
    if (validatedLink.valid && validatedLink.visited) {
        findNextSection = this.possibleSections.find(currentSection => currentSection.navNumber === validatedLink.navNumber + 1);
        if (findNextSection) {
          findNextSection.locked = false;
          findNextSection.disabled = false;
          findNextSection.visited = true;
          this.setResumeMenuValidation(findNextSection.name);
        }
      }

      // also update section status
    this.updateSectionStatus(sectionName, validatedLink);

    if (this.router.url.includes('/thank-you')) {
      validatedLink.valid = true;
      validatedLink.locked = false;
    }
  }

  updateMenuItemsFromFormValues(jsonFormData: {}, menuItems: MenuItem[]): MenuItem[] {
    menuItems?.forEach(section => {
      const sectionName = section.name;
      const sectionStatus: SectionStatus = jsonFormData[sectionName]?.sectionStatus;
      section.valid = sectionStatus.valid;
      section.active = sectionStatus.active;
      section.disabled = sectionStatus.disabled;
      section.visited = sectionStatus.visited;
      section.locked = sectionStatus.locked;
    });
    return menuItems;
  }

  sectionIsValid(sectionName: string): boolean {
    const theSection = this.possibleSections.find(thisSection => thisSection.name === sectionName);
    return theSection.valid;
  }

  edSectionSetup() {
    this.programSub.subscribe(program => {
      if (
        !environment.isAgent && program.campCode === 'R'
        && program.levelCode === 'UG'
      ) {
        if (
          !this.checkForEnrollmentDeposit
          && !this.applicationService.spcApp
          && !this.applicationService.festivalsApp
          && this.possibleSections.some(section => section.name !== 'optional')
        ) {
          this.maybeAddSection(optionalSection);
          if (this.mainForm.get('optional') && this.mainForm.get('optional').get('sectionStatus')) {

            this.mainForm.get('optional').get('sectionStatus').setValue({
              visible: true,
              visited: false,
              valid: false,
              active: false,
              disabled: false,
              locked: true
            });
          }
        }
        this.checkForEnrollmentDeposit = true;
      } else if (!environment.isAgent) {
        this.checkForEnrollmentDeposit = false;
        const enrollmentDepositIndex = this.possibleSections.findIndex(thisSection => thisSection.name === 'optional');
        if (enrollmentDepositIndex !== -1) {
          this.possibleSections.splice(enrollmentDepositIndex, 1);
          this.mainForm.get('optional').get('sectionStatus').setValue({
            visible: false,
            visited: false,
            valid: false,
            active: false,
            disabled: false,
            locked: true
          });
        }
      }
      if (environment.isAgent && program.campCode === 'R' && this.programService.isUndergrad(program)) {
        this.checkForEnrollmentDeposit = true;
      } else if (environment.isAgent) {
        this.checkForEnrollmentDeposit = false;
        const enrollmentDepositIndex = this.possibleSections.findIndex(thisSection => thisSection.name === 'enrollmentDeposit');
        if (enrollmentDepositIndex !== -1) {
          this.possibleSections.splice(enrollmentDepositIndex, 1);
        }
      }
    });
  }

  maybeAddSection(checkSection: MenuItem) {
    if (this.possibleSections.findIndex(thisSection => thisSection.name === checkSection.name) === -1) {
      this.possibleSections.push(checkSection);
    }
  }

  checkLockedAndActiveSection(menuItems = this.possibleSections) {
    if (!environment.isAgent) {
      menuItems.forEach(section => {
        section.active = section.name === this.activeSection.name;
        section.locked = !section.visited;
        if (section.name === 'optional' && !section.visited) {
          section.locked = true;
        }
      });
    }
  }

  getUnlockedSections(): MenuItem[] {
    return this.possibleSections.filter((item: MenuItem) => item.locked === false);
  }

  getPrevAvailableNavItem(currentNavItem: MenuItem): MenuItem {
    // find the current nav item among all unlocked ones
    let currentActiveIndex: number = this.getUnlockedSections().findIndex(item => item.navNumber === currentNavItem.navNumber);
    if (currentActiveIndex === 0) {
      currentActiveIndex = this.getUnlockedSections().length;
    }
    return this.getUnlockedSections()[currentActiveIndex - 1];
  }

  getNextAvailableNavItem(currentNavItem: MenuItem): MenuItem {
    // find the current nav item among all unlocked ones
    let currentActiveIndex: number = this.getUnlockedSections().findIndex(item => item.navNumber === currentNavItem.navNumber);
    if (currentActiveIndex === this.getUnlockedSections().length - 1) {
      currentActiveIndex = -1;
    }
    return this.getUnlockedSections()[currentActiveIndex + 1];
  }

  generateNavItems(): MenuItem[] {
    const returnedNavItems = [
      {
        name: 'yourDegree',
        text: 'Your Degree',
        nameAttribute: 'Degree Selection',
        mobileText: 'Degree',
        locked: false,
        visited: false,
        valid: false,
        active: false,
        disabled: false,
        navNumber: 1
      },
      {
        name: 'previousEducation',
        text: 'Previous Education',
        nameAttribute: 'Previous Education',
        mobileText: 'Education',
        locked: false,
        visited: false,
        valid: false,
        active: false,
        disabled: false,
        navNumber: 2
      },
      {
        name: 'personalInformation',
        text: 'Personal Information',
        nameAttribute: 'Personal Information',
        mobileText: 'Personal',
        locked: false,
        visited: false,
        valid: false,
        active: false,
        disabled: false,
        navNumber: 3
      },
      {
        name: 'agreeSubmit',
        text: 'Agree & Submit',
        nameAttribute: 'Agree & Review',
        mobileText: 'Submit',
        locked: false,
        visited: false,
        valid: false,
        active: false,
        disabled: false,
        navNumber: 4
      }
    ];

    if (environment.isAgent) {
      returnedNavItems.unshift(agentQSection);
    }

    return returnedNavItems;
  }

  private updateSectionStatus(sectionName: string, currentValidedSection: MenuItem) {
    const sectionStatus: SectionStatus = this.mainForm.get(sectionName)?.get('sectionStatus')?.value;
    if (sectionStatus) {
      sectionStatus.valid = currentValidedSection.valid;
      sectionStatus.active = currentValidedSection.active;
      sectionStatus.disabled = currentValidedSection.disabled;
      sectionStatus.visited = currentValidedSection.visited;
      sectionStatus.locked = currentValidedSection.locked;
    }
  }

  private setResumeMenuValidation(name: string) {

    if (name === 'previousEducation' || name === 'personalInformation' || name === 'optional') {
      if (this.mainForm.get(name)) {
        const sectionStatus: SectionStatus = this.mainForm.get(name).get('sectionStatus').value;
        sectionStatus.valid = false;
        sectionStatus.locked = false;
        sectionStatus.disabled = false;
        sectionStatus.visited = true;
      } else {
        console.error(`Section: ${name} is null and was incorrectly added to the menuItems.
        IsAgent: ${environment.isAgent}.
        Program: ${this.programService.getCurrentProgram()}.`)
      }
    }
  }
}
